import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem',
  },

  primaryText: {
    fontSize: '0.95rem',
    fontWeight: 700,
    color: '#141414',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '1em',
  },
  secondaryText: {
    fontSize: '.8rem',
    fontWeight: 500,
    color: '#141414',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '16px',
    display: 'block',
    marginTop: 6
  },
})
